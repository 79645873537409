import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import Link from 'next/link'
import { copyToClipboard } from '../../../lib/utils/copyToClipboard'
import { Tooltip } from 'react-tooltip'

export default function ReferralBento() {
  const { t } = useTranslation('home')

  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleClickReferralCode = async (referralCode: string) => {
    copyToClipboard(referralCode).then(() => {
      setTooltipVisible(true) // Show the tooltip
      setTimeout(() => {
        setTooltipVisible(false) // Hide after 3 seconds
      }, 3000)
    })
  }

  return (
    <div className="group pointer-events-none relative flex h-full w-full flex-col blur-sm">
      <div className="grow border-b border-dashed pb-1.5">
        <Link href="#">
          <div className="flex items-center pb-1.5">
            <p className="font-bold text-neutralBlack/80">
              {t('bento.referralBento.title')}
            </p>
            <HeroIcons.ChevronRightIcon className="h-5 w-5 text-neutralBlack/80" />
          </div>

          <div className="flex space-x-3">
            <div>
              <img
                src="/img/icons/3d/keys-min.png"
                className="h-16 w-16 group-hover:animate-hover sm:top-6"
                alt="keys icon 3d"
              />
            </div>
            <div className="flex-1">
              <p className="font-medium text-neutralBlack">
                {t('bento.referralBento.subtitle')}
              </p>
              <p className="text-xs text-neutralBlack/80 ">
                {t('bento.referralBento.text')}
              </p>
            </div>
          </div>
        </Link>
      </div>

      <div className="pt-1.5">
        <div className="">
          <p className="font-light text-neutralBlack/80">
            {t('bento.referralBento.yourCode')}:
          </p>
          <button
            className="flex items-center"
            onClick={() => handleClickReferralCode('COMINGSOON')}
            data-tooltip-id="copiedToClipboardReferralCode"
          >
            <Tooltip
              place="top"
              id="copiedToClipboardReferralCode"
              className="z-40 max-w-[97%] text-center transition ease-in-out"
              isOpen={tooltipVisible}
            >
              {t('bento.referralBento.codeCopied')}
            </Tooltip>
            <p className="font-medium text-neutralBlack">COMINGSOON</p>
            <HeroIcons.ClipboardDocumentIcon
              className="ml-1.5 h-6 w-6 items-center"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  )
}
