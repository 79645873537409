import React from 'react'
import { useTranslation } from 'next-i18next'
import {
  getFractionCount,
  getRentalYieldPerMonth,
  getTotalPortfolioValue,
  useSelector,
} from '../../../store'
import { formatCurrency } from '../../../lib/utils/formatNumber'
import useLocale from '../../../hooks/useLocale'
import useCurrency from '../../../hooks/useCurrency'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import Link from 'next/link'
import routes from '../../../config/routes'
import { classNames } from '../../../lib/utils/classNames'

export default function FractionsCardPreview() {
  const { t } = useTranslation('home')
  const { locale } = useLocale()
  const { currency } = useCurrency()

  const fractionCount = useSelector(getFractionCount)
  const totalPortfolioValue = useSelector(getTotalPortfolioValue)
  const currentMonthlyYields = useSelector(getRentalYieldPerMonth)

  return (
    <div className="relative flex flex-col overflow-hidden rounded-md bg-white p-3">
      <img
        src="/img/icons/3d/rental-home-min.png"
        className="absolute -right-4 -top-12 z-0 h-32 w-auto rotate-[6deg] scale-x-[-1]"
        alt="rental house icon 3d"
      />

      <div className={classNames('grow', fractionCount > 0 ? 'mb-3 ' : '')}>
        <p className="text-sm text-neutralBlack/80">
          {t('dashboardPreview.fractionsCard.title')}
        </p>
        {fractionCount > 0 && (
          <div className="flex items-baseline space-x-1">
            <p className="text-3xl font-medium text-neutralBlack">
              {fractionCount}
            </p>
            <p className="text-neutralBlack">
              {fractionCount > 1
                ? t('dashboardPreview.fractionsCard.fractions')
                : t('dashboardPreview.fractionsCard.fraction')}
            </p>
          </div>
        )}
      </div>

      {fractionCount > 0 ? (
        <div className="z-10 grid grow grid-cols-1 gap-3 sm:grid-cols-2">
          <div className="h-full w-full rounded-md bg-gradient-to-r from-[#2B2E9A] to-[#846AC6] p-[2px]">
            <div className="flex h-full w-full flex-col rounded-[4px] bg-neutralWhite px-4 py-3">
              <p className="text-sm text-[#2B2E9A]">
                {t('dashboardPreview.fractionsCard.totalValue')}
              </p>
              <p className="w-fit bg-gradient-to-r from-[#2B2E9A] to-[#846AC6] bg-clip-text text-3xl font-medium text-transparent">
                {formatCurrency(totalPortfolioValue, 2, true, currency, locale)}
              </p>
            </div>
          </div>

          <div className="z-10 flex flex-col rounded-md bg-purpleGradient px-4 py-3">
            <p className="text-sm text-neutralWhite/90">
              {t('dashboardPreview.fractionsCard.estimatedMonthlyYield')}
            </p>
            <p className="flex grow items-center text-3xl font-medium text-neutralWhite">
              +{formatCurrency(currentMonthlyYields, 2, true, currency, locale)}
            </p>
          </div>
        </div>
      ) : (
        <div className="z-10 flex h-full flex-col items-center justify-center space-y-1.5 sm:mt-1.5">
          <div>
            <p className="text-center font-medium text-neutralBlack/90">
              {t('dashboardPreview.fractionsCard.noFractionsYet.title')}
            </p>
          </div>
          <div>
            <Link
              href={routes.properties}
              className="group flex items-center overflow-hidden rounded-lg border border-sheltersBlue bg-sheltersBlue px-3 py-1.5 text-neutralWhite transition hover:bg-white hover:text-sheltersBlue"
            >
              <div className="flex items-center space-x-1">
                <div className="text-sm">
                  {t('dashboardPreview.fractionsCard.noFractionsYet.ctaButton')}
                </div>
                <HeroIcons.ShoppingCartIcon
                  className="h-5 w-auto group-hover:animate-rollRight"
                  aria-hidden="true"
                />
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
