import React from 'react'
import { useTranslation } from 'next-i18next'
import PayoutsHistoryBarCharts from './PayoutsHistoryBarCharts'

export default function YieldHistoryCard() {
  const { t } = useTranslation('home')

  return (
    <div className="flex flex-col rounded-md bg-white p-3">
      <p className="text-sm text-neutralBlack/80">
        {t('dashboardPreview.yieldHistoryCard.title')}
      </p>
      <div className="-ml-1.5 mt-1.5 h-36 grow">
        <PayoutsHistoryBarCharts />
      </div>
    </div>
  )
}
