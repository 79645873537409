import React, { useEffect, useState } from 'react'
import { useAuth } from '../providers/AuthProvider'
import { useTranslation } from 'next-i18next'
import { getUserInfoFlowStatus, useSelector } from '../store'
import { HeroIcons } from '../lib/utils/heroIcons'
import routes from '../config/routes'
import Link from 'next/link'
import { classNames } from '../lib/utils/classNames'

export default function CompleteProfileBanner() {
  const { t } = useTranslation('common')

  const userInfoFlowStatus = useSelector(getUserInfoFlowStatus)

  const { isLoggedIn, isLoading } = useAuth()

  const [isSmallDisplay, setIsSmallDisplay] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        setIsSmallDisplay(true)
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!isLoggedIn || !userInfoFlowStatus || isLoading) {
    return <></>
  }

  if (
    userInfoFlowStatus.userInfo &&
    userInfoFlowStatus.billingAddress &&
    userInfoFlowStatus.wallet &&
    userInfoFlowStatus.kyc
  ) {
    return <></>
  }

  return (
    <>
      <div className="rounded-md border border-yellow-200 bg-yellow-100/60 p-2 pt-3 backdrop-blur-sm">
        <div className="flex h-fit">
          <div className="shrink-0">
            <HeroIcons.ExclamationTriangleIcon
              aria-hidden="true"
              className="mr-1.5 h-5 w-5 text-yellow-400"
            />
          </div>

          <div className="w-full">
            <div className="flex w-full">
              <div className="grow">
                <p className="text-sm font-medium text-yellow-800">
                  {t('completeProfileBanner.title')}
                </p>
              </div>
              <div>
                <button
                  onClick={() => {
                    setIsSmallDisplay(!isSmallDisplay)
                  }}
                >
                  {isSmallDisplay ? (
                    <HeroIcons.ChevronDownIcon className="h-5 w-5 text-neutralBlack/80" />
                  ) : (
                    <HeroIcons.XMarkIcon className="h-5 w-5 text-neutralBlack/80" />
                  )}
                </button>
              </div>
            </div>

            <div
              className={classNames(
                isSmallDisplay ? 'hidden' : 'flex items-end',
              )}
            >
              <div className="grow">
                <p className="text-sm text-yellow-700">
                  {t('completeProfileBanner.text')}
                </p>
                <p className="text-sm font-medium text-yellow-700">
                  {t('completeProfileBanner.subtext')}
                </p>
              </div>
              <div>
                <div
                  className={classNames(
                    'hidden sm:block',
                    isSmallDisplay ? 'hidden' : '',
                  )}
                >
                  <Link
                    href={routes.account}
                    className="flex items-center rounded-lg border border-sheltersBlue bg-sheltersBlue px-3 py-1 text-neutralWhite transition hover:bg-white hover:text-sheltersBlue"
                  >
                    <div className="flex items-center space-x-1">
                      <div className="text-sm">
                        {t('completeProfileBanner.button')}
                      </div>
                      <HeroIcons.ArrowRightIcon
                        className="mx-auto h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            isSmallDisplay ? 'hidden' : '',
            'block pt-3 sm:hidden',
          )}
        >
          <Link
            href={routes.account}
            className="flex items-center rounded-lg border border-sheltersBlue bg-sheltersBlue px-3 py-1.5 text-neutralWhite transition hover:bg-white hover:text-sheltersBlue"
          >
            <div className="flex items-center space-x-1">
              <div className="text-sm">{t('completeProfileBanner.button')}</div>
              <HeroIcons.ArrowRightIcon
                className="mx-auto h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
