import React, { useEffect, useRef } from 'react'
import { PayoutHistory } from '../../../types/User'
import Chart from 'chart.js/auto'
import useCurrency from '../../../hooks/useCurrency'
import useLocale from '../../../hooks/useLocale'
import { DateFormat, formatDateString } from '../../../lib/utils/formatDate'
import { formatCurrency } from '../../../lib/utils/formatNumber'
import { getPayoutsHistory, useSelector } from '../../../store'
import { useTranslation } from 'next-i18next'

const getBarThickness = (dataLength: number) => {
  if (dataLength === 1) {
    return 60
  }
  if (dataLength <= 6) {
    return 40
  }
  return undefined
}

export default function PayoutsHistoryBarCharts() {
  const { t } = useTranslation('home')

  const payoutsHistory = useSelector(getPayoutsHistory)

  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<Chart | null>(null)
  const { currency } = useCurrency()
  const { locale } = useLocale()

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      const ctx = chartRef.current.getContext('2d')
      if (ctx) {
        const gradient = ctx.createLinearGradient(
          0,
          0,
          0,
          chartRef.current.height,
        )
        gradient.addColorStop(0, '#2B2E9A')
        gradient.addColorStop(1, '#846AC6')

        const lastSixMonthsData = payoutsHistory.slice(-12)

        const data = lastSixMonthsData.map(
          (item: PayoutHistory) => item.totalAmount,
        )
        const labels = lastSixMonthsData.map((item: PayoutHistory) =>
          formatDateString(item.paidAt, locale, DateFormat.MONTH_XS),
        )

        // @ts-ignore
        const chartConfig: Chart.ChartConfiguration = {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                fill: true,
                lineTension: 1,
                backgroundColor: gradient,
                borderRadius: {
                  topLeft: 5,
                  topRight: 5,
                  bottomLeft: 0,
                  bottomRight: 0,
                },
                barThickness: getBarThickness(data.length),
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                type: 'category',
                grid: {
                  display: false,
                },
                ticks: {
                  padding: -1.5,
                },
              },
              y: {
                beginAtZero: true,
                grace: '20%',
                ticks: {
                  callback: function (value: number) {
                    if (value === 0) return ''
                    return formatCurrency(
                      value,
                      value < 100 ? 2 : 0,
                      true,
                      currency,
                      locale,
                    )
                  },
                  display: false,
                },
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                boxHeight: 0,
                boxWidth: 0,
                callbacks: {
                  title: function (context: any) {
                    return formatDateString(
                      lastSixMonthsData[context[0].dataIndex].paidAt,
                      locale,
                      DateFormat.PLAIN_DATE,
                    )
                  },
                  label: function (context: any) {
                    const value = context.parsed.y || 0
                    return `${formatCurrency(value, 2, true, currency, locale)}`
                  },
                },
              },
            },
          },
        }

        chartInstanceRef.current = new Chart(ctx, chartConfig)
      }
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [payoutsHistory, locale])

  if (payoutsHistory.length < 1) {
    return (
      <div className="relative flex h-full flex-col items-center justify-center space-y-1.5 p-3">
        <img
          src="/img/icons/3d/sale-min.png"
          className=" h-28 w-auto -rotate-6 animate-hover"
          alt="sale icon 3d"
        />
        <p className="text-center text-sm font-light text-neutralBlack/80">
          {t('dashboardPreview.yieldHistoryCard.noPayoutYet')}
        </p>
      </div>
    )
  }

  return <canvas ref={chartRef} />
}
