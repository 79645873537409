import React from 'react'
import { Tooltip } from 'react-tooltip'
import { formatNumber, formatPercentage } from '../../lib/utils/formatNumber'
import { useTranslation } from 'next-i18next'

interface ProgressBarProps {
  fractionCount: number
  fractionFunded: number
  id: string
  lineHeight: number
  hidePercent?: boolean
  hideTooltip?: boolean
}

export default function ProgressBar({
  fractionCount,
  fractionFunded,
  id,
  lineHeight,
  hidePercent,
  hideTooltip,
}: ProgressBarProps) {
  const { t } = useTranslation('originals/global')

  const progress = Math.round((fractionFunded / fractionCount) * 100)

  const fillStyle = {
    width: `${progress}%`,
  }

  const dataTooltipContent = (
    <div>
      <div className="text-sm font-thin text-neutralWhite">
        {t('financingProgress.totalOnSale')}{' '}
        <strong>{formatNumber(fractionCount)}</strong>
      </div>
      <div className="mb-4 text-sm font-thin text-neutralWhite">
        {t('financingProgress.totalSold')}{' '}
        <strong>{formatNumber(fractionFunded)}</strong>
      </div>
      <div className="text-sm font-thin text-neutralWhite">
        {t('financingProgress.totalAvailable')}{' '}
        <strong>{formatNumber(fractionCount - fractionFunded)}</strong>
      </div>
    </div>
  )

  return (
    <div
      className="relative flex items-center"
      data-tooltip-id={'progressBarTooltip-' + id}
    >
      <div
        className={`h-${lineHeight} w-full items-center overflow-hidden rounded-full bg-neutralGray/40`}
      >
        <div
          className="h-full bg-purpleGradient transition-all "
          style={fillStyle}
        />
      </div>
      {!hidePercent && (
        <div className="ml-2 items-center text-xs font-thin text-neutralBlack">
          {formatPercentage(progress, 0, false)}
        </div>
      )}
      {!hideTooltip && (
        <Tooltip
          place="bottom"
          id={'progressBarTooltip-' + id}
          className="z-40"
        >
          {dataTooltipContent}
        </Tooltip>
      )}
    </div>
  )
}
