const routes = {
  index: '/',
  login: '/login/',
  register: '/register/',
  forgotPassword: '/forgot-password/',
  logout: '/logout/',
  registerSuccess: '/register-success/',
  dashboard: '/dashboard/',
  account: '/account/',
  marketplace: '/marketplace/',
  properties: '/properties/',
  property: '/properties/[propertyId]/',
  checkoutQueue: '/properties/[propertyId]/checkout/queue',
  checkoutOverview: '/properties/[propertyId]/checkout/overview',
  checkoutTimeout: '/properties/[propertyId]/checkout/timeout',
  checkoutComplete: '/properties/[propertyId]/checkout/complete/',
  checkout: '/properties/[propertyId]/checkout/',
  order: '/orders/[orderId]/',
}

export default routes
