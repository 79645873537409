import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import Layout from '../components/Layout'
import LatestListingCarousel from '../components/home/carousel/LatestListingCarousel'
import MoreAboutBento from '../components/home/bento/MoreAboutBento'
import DashboardPreviewCard from '../components/home/dashboard-preview/DashboardPreviewCard'

function HomePage() {
  const { t } = useTranslation('home')

  return (
    <>
      <NextSeo
        title={t('seo.title') || ''}
        description={t('seo.description') || ''}
      />
      <div>
        <div>
          <LatestListingCarousel />
        </div>
        <div className="pt-3">
          <DashboardPreviewCard />
        </div>
        <div className="mt-3">
          <MoreAboutBento />
        </div>
      </div>
    </>
  )
}

HomePage.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>
}

export default HomePage

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'home',
        'common',
        'originals/global',
      ])),
    },
  }
}
