import React from 'react'
import { useTranslation } from 'next-i18next'
import routes from '../../../config/routes'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import Link from 'next/link'

export default function SeeAllOpportunitiesSlide() {
  const { t } = useTranslation('home')

  return (
    <div className="flex h-full w-full flex-col bg-purpleGradient">
      <div className="relative grow">
        <img
          src="/img/icons/3d/house-sm-min.png"
          className="absolute -left-3 top-28 z-10 h-28 -rotate-6 sm:top-24"
          alt="house icon 3d"
        />
        <img
          src="/img/icons/3d/keys-min.png"
          className="absolute -right-3 -top-3 z-10 h-28 -rotate-6 sm:top-6"
          alt="house icon 3d"
        />
        <img
          src="/img/logos/shelters/logotype-white.svg"
          className="absolute left-1/2 top-1/2 h-12 -translate-x-1/2 -translate-y-1/2"
          alt="house icon 3d"
        />
      </div>

      <Link
        href={routes.properties}
        className="z-10 bg-indigo-300/10 p-3 backdrop-blur"
      >
        <div className="flex items-center font-bold text-neutralWhite">
          <p className="grow">
            {t('carousel.slides.seeOtherOpportunities.ctaLink')}
          </p>
          <HeroIcons.ArrowRightIcon
            className="h-6 w-auto rounded-full border border-neutralWhite p-1"
            aria-hidden="true"
          />
        </div>
      </Link>
    </div>
  )
}
