function calculateCompoundYield(
  principal: number,
  rate: number,
  years: number,
): number {
  const totalGain = principal * Math.pow(1 + rate, years)
  return totalGain - principal
}

function calculateRentalIncome(
  principal: number,
  monthlyRate: number,
  years: number,
): number {
  return principal * years * monthlyRate
}

const profitabilitySimulatorCalculation = (
  initialInvestment: number,
  yearlyYield: number,
  yearlyPriceAppreciation: number,
  totalPeriod: number,
): {
  totalYieldGain: number
  totalPriceAppreciation: number
  totalProfitability: number
} => {
  const totalYieldGain = calculateRentalIncome(
    initialInvestment,
    Number(yearlyYield / 10000),
    totalPeriod,
  )
  const totalCompoundedPriceAppreciation = calculateCompoundYield(
    initialInvestment,
    Number(yearlyPriceAppreciation / 10000),
    totalPeriod,
  )
  const totalProfitability = Number(
    totalCompoundedPriceAppreciation + totalYieldGain,
  )

  return {
    totalYieldGain: totalYieldGain,
    totalPriceAppreciation: totalCompoundedPriceAppreciation,
    totalProfitability: totalProfitability,
  }
}

export { profitabilitySimulatorCalculation }
