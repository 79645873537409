export interface VeriffSession {
  status: VeriffSessionCreateStatus
  verification: VeriffVerification
}

export interface VeriffVerification {
  id: string
  url: string
  vendorData: string
  host: string
  status: VeriffSessionVerificationStatus
  sessionToken: string
}

export enum VeriffSessionCreateStatus {
  SUCCESS = 'success',
}

export enum VeriffSessionVerificationStatus {
  CREATED = 'created',
}

export enum VeriffDecisionVerificationStatus {
  APPROVED = 'approved',
  RESUBMISSION_REQUESTED = 'resubmission_requested',
  REVIEW = 'review',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  ABANDONED = 'abandoned',
}

export enum VeriffEventAction {
  STARTED = 'started',
  SUBMITTED = 'submitted',
}
