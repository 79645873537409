import React from 'react'
import { useTranslation } from 'next-i18next'
import { Property } from '../../../types/Property/Property'
import ContributorsCount from '../../originals/property/BuyingSection/ContributorsCount'
import ProgressBar from '../../originals/ProgressBar'
import Link from 'next/link'
import routes from '../../../config/routes'

interface LatestFundingPropertySlideProps {
  property: Property
  onClick?: (property: Property) => void
}

export default function LatestFundingPropertySlide({
  property,
  onClick,
}: LatestFundingPropertySlideProps) {
  const { t } = useTranslation('home')

  return (
    <Link
      href={{ pathname: routes.property.replace('[propertyId]', property.id) }}
      onClick={() => onClick?.(property)}
      className="h-full w-full bg-purpleGradient"
    >
      <div className="absolute left-3 top-3 rounded-lg bg-purpleGradient px-1.5 py-0.5">
        <p className="text-xs font-medium text-neutralWhite">
          {t('carousel.slides.latestFunding.title')}
        </p>
      </div>

      <div className="h-4/5">
        {property.images && property.images[0] ? (
          <img
            src={property.images[0].url}
            className="aspect-[16/9] h-full w-full bg-neutralGray/30 object-cover"
            alt={property.translation.title + ' photo'}
          />
        ) : (
          <img
            src="/img/logos/shelters/logotype-white.svg"
            className="absolute left-1/2 top-[40%] h-12 -translate-x-1/2 -translate-y-1/2"
            alt="Shelters logo 3d"
          />
        )}
      </div>

      <div className="absolute bottom-0 right-0 w-full bg-white p-3">
        <p className="font-medium text-neutralBlack">
          {property.translation.title}
        </p>
        <div className="flex items-center space-x-8">
          <div className="grow">
            <ProgressBar
              fractionCount={property.fractionCount}
              fractionFunded={property.fractionFunded}
              id={property.id}
              lineHeight={2}
            />
          </div>
          <div className="flex items-center">
            <ContributorsCount property={property} smallText={true} />
          </div>
        </div>
      </div>
    </Link>
  )
}
