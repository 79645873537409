import React, { Fragment } from 'react'
import { NextRouter, useRouter } from 'next/router'
import i18nextConfig from '../../next-i18next.config'
import { Transition, Menu } from '@headlessui/react'
import { HeroIcons } from '../lib/utils/heroIcons'
import Link from 'next/link'
import {
  languageFlagMapping,
  languageLabelMapping,
} from '../constants/languageMapping'
import useLocale from '../hooks/useLocale'
import { classNames } from '../lib/utils/classNames'
import userService from '../services/user.service'
import { useAuth } from '../providers/AuthProvider'

interface LanguageSelectProps {
  minimalDisplay?: boolean
}

export default function LanguageSelect({
  minimalDisplay = false,
}: LanguageSelectProps) {
  const router: NextRouter = useRouter()
  const { locale, setLocale } = useLocale()
  const { isLoggedIn } = useAuth()

  const supportedLngs: string[] = i18nextConfig.i18n.locales

  const onLanguageChange = async (newLocale: string) => {
    await setLocale(newLocale)
    if (isLoggedIn) {
      await userService.updateUserPreference({
        // @ts-ignore
        lang: newLocale,
      })
    }
  }

  return (
    <div className="cursor-pointer">
      <Menu>
        <div className="relative">
          <Menu.Button
            className={classNames(
              'relative flex w-full cursor-default items-center text-sm text-neutralBlack shadow-2xl hover:cursor-pointer',
              minimalDisplay
                ? ''
                : 'rounded-2xl border border-neutralGray/50 bg-white px-2 py-1',
            )}
          >
            <div className="pt-0.5">{languageFlagMapping[locale]}</div>
            {!minimalDisplay && (
              <div className="ml-auto pl-1.5 text-neutralBlack">
                {languageLabelMapping[locale]}
              </div>
            )}
            <span className="pointer-events-none ml-auto">
              <HeroIcons.ChevronDownIcon
                className="h-5 w-5 text-neutralBlack/50"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items
              className={classNames(
                'absolute z-40 mt-1 max-h-60 w-full space-y-1 overflow-y-scroll rounded-md border border-neutralGray/50 bg-neutralWhite text-sm shadow-lg',
                minimalDisplay ? '' : 'top-9',
              )}
            >
              {supportedLngs.map((lng) => (
                <Menu.Item key={lng} as={Fragment}>
                  <Link
                    href={router.asPath}
                    locale={lng}
                    onClick={() => onLanguageChange(lng)}
                    className={classNames(
                      'flex items-center px-3 py-0.5',
                      minimalDisplay ? 'justify-center' : '',
                    )}
                  >
                    <span
                      className={classNames(
                        'text-neutralBlack',
                        minimalDisplay ? '' : 'pr-3 pt-1',
                      )}
                    >
                      {languageFlagMapping[lng]}
                    </span>
                    {!minimalDisplay && (
                      <span
                        className={`block ${
                          locale === lng ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {languageLabelMapping[lng]}
                      </span>
                    )}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  )
}
