import React from 'react'
import AlertsConsumer from './AlertsConsumer'
import routes from '../config/routes'
import { HeroIcons } from '../lib/utils/heroIcons'
import { useTranslation } from 'next-i18next'
import { NavigationItem, NavigationPages } from '../types/Navigation'
import { useRouter } from 'next/router'
import Navbar from './Navbar'
import isRouteActive from '../lib/utils/isRouteActive'
import CompleteProfileBanner from './CompleteProfileBanner'
import { Toaster } from 'react-hot-toast'

type LayoutProps = {
  children: React.ReactNode
  hideBanner?: boolean
}

export default function Layout({ children, hideBanner = false }: LayoutProps) {
  const { t } = useTranslation('common')
  const router = useRouter()

  const navigation: NavigationItem[] = [
    {
      id: NavigationPages.HOME,
      name: t('navbar.home'),
      href: routes.index,
      icon: HeroIcons.HomeIcon,
      isActive: isRouteActive(routes.index, router.asPath),
    },
    {
      id: NavigationPages.STORE,
      name: t('navbar.properties'),
      href: routes.properties,
      icon: HeroIcons.BuildingStorefrontIcon,
      isActive: isRouteActive(routes.properties, router.asPath),
    },
    {
      id: NavigationPages.ACCOUNT,
      name: t('navbar.account'),
      href: routes.account,
      icon: HeroIcons.Cog6ToothIcon,
      isActive: isRouteActive(routes.account, router.asPath),
    },
  ]

  return (
    <main className="min-h-screen bg-slate-100">
      <AlertsConsumer />
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: '1px solid green',
            },
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
        }}
      />
      <div className="px-3 pb-3 pt-0 sm:pt-3 lg:px-32 xl:px-64">
        <div className="sticky top-0 z-40 w-full space-y-1.5 pt-3 sm:relative">
          <Navbar navigation={navigation} />
          {hideBanner ? (
            <></>
          ) : (
            <>
              <CompleteProfileBanner />
            </>
          )}
        </div>
        <div className="pt-3">{children}</div>
      </div>
    </main>
  )
}
