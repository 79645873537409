import dayjs from 'dayjs'

export const enum DateFormat {
  PLAIN_DATE = 'LL',
  CON_PLAIN_DATE = 'll',
  FULL_DATE_TIME = 'LLLL',
  CON_FULL_DATE_TIME = 'llll',
  CALENDAR_DATE = 'L',
  MONTH_YEAR = 'MMM YYYY',
  MONTH_XS = 'MMM',
  CUSTOM_FULL = 'dddd DD MMMM - HH:MM',
  CUSTOM = 'dddd DD MMMM',
}

export const formatDateString = (
  dateString: string,
  locale: string,
  dateFormat?: string,
): string => {
  return dayjs(dateString)
    .locale(locale)
    .format(dateFormat ?? DateFormat.PLAIN_DATE)
}
