import api from './api'
import {
  BillingAddress,
  NextPayout,
  SheltersNft,
  User,
  getBillingAddressesResponse,
  UserInfo,
  UserWallet,
  UserPreferences,
} from '../types/User'
import { apiMultiversx } from './api-multiversx'
import { PostAuthEmailResponse } from '../types/APIResponse'
import {
  getUserPayoutMethodsResponse,
  PayoutCurrency,
  ResponseGetPayoutsHistory,
  UserPayoutMethod,
} from '../types/Payout'
import { CreateOrderResult, GetOrdersResult } from '../types/Order/Order'
import { getBaseUrl } from '../lib/utils/language'
import { VeriffSession } from '../types/Veriff'
import { CurrencyEnum } from '../types/Currency'
import { GetUserSessionResult } from '../types/UserSession'
import { Language } from '../types/Language'

const UserService = {
  async registerWithEmail(email: string, password: string): Promise<void> {
    const { data } = await api.post('/v1/auth/register/email', {
      email,
      password,
    })
  },
  async loginWithEmail(
    email: string,
    password: string,
  ): Promise<PostAuthEmailResponse> {
    const { data } = await api.post('/v1/auth/login/email', {
      email,
      password,
    })
    return data
  },
  async logout(): Promise<void> {
    await api.post('/v1/auth/logout')
  },
  async confirmEmail(email: string, token: string): Promise<any> {
    return api.post('/v1/auth/confirm-email', { email, token })
  },
  async forgotPassword(email: string): Promise<any> {
    const { data } = await api.post('/v1/auth/reset-password', { email })
    return data
  },
  async changePassword(
    userId: string,
    resetToken: string,
    password: string,
  ): Promise<any> {
    const { data } = await api.post('/v1/auth/change-password', {
      userId,
      resetToken,
      password,
    })
    return data
  },
  async getWallets(): Promise<UserWallet[]> {
    const { data } = await api.get('/v1/users/me/wallets')
    return data
  },
  async deleteWallet(walletId: string): Promise<void> {
    await api.delete(`/v1/users/me/wallets/${walletId}`)
  },
  async createWallet(
    address: string,
    signature: string,
    authToken: string,
    name: string,
  ): Promise<any> {
    const { data } = await api.post('/v1/users/me/wallets', {
      address,
      signature,
      authToken,
      name,
    })
    return data
  },
  async createWalletV2(accessToken: string, name: string): Promise<any> {
    const { data } = await api.post('/v2/users/me/wallets', {
      accessToken,
      name,
    })
    return data
  },
  async createWalletless(): Promise<UserWallet> {
    const { data } = await api.post('/v1/users/me/wallets/walletless')
    return data
  },
  async getSessions(): Promise<GetUserSessionResult> {
    const { data } = await api.get('/v1/users/me/sessions')
    return data
  },
  async destroySession(sessionId: string): Promise<any> {
    await api.delete(`/v1/users/me/sessions/${sessionId}`)
  },
  async getUser(): Promise<User> {
    const { data } = await api.get('/v1/users/me')
    return data
  },
  async updateUser(userInfo: UserInfo): Promise<User> {
    const { data } = await api.put('/v1/users/me', userInfo)
    return data
  },
  async getUserPreferences(): Promise<UserPreferences> {
    const { data } = await api.get('/v1/users/me/preferences')
    return data
  },
  async updatePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<any> {
    return api.post('/v1/users/me/password', { currentPassword, newPassword })
  },
  async getNfts(
    address: string,
    identifiers: string[],
  ): Promise<SheltersNft[]> {
    const { data } = await apiMultiversx.get('/accounts/' + address + '/nfts', {
      params: { identifiers: identifiers.join(',') },
    })
    return data.map(({ identifier, ticker, nonce, balance }: any) => ({
      identifier,
      ticker,
      nonce,
      balance: parseInt(balance),
    }))
  },
  async getNextYields(nfts: SheltersNft[]): Promise<NextPayout[]> {
    const { data } = await api.get('/v1/users/me/payouts/next', {
      params: { nfts },
    })
    return data
  },
  async getPayoutsHistory(
    currency: CurrencyEnum,
    month?: number,
  ): Promise<ResponseGetPayoutsHistory> {
    const { data } = await api.get('/v1/users/me/payouts/history', {
      params: { month, currency },
    })
    return data
  },
  async initVerification(
    callbackUrl: string,
    language: string,
  ): Promise<VeriffSession> {
    const { data } = await api.post('/v1/users/me/verifications', {
      callbackUrl: callbackUrl,
      language: language,
    })
    return data
  },
  async getBillingAddresses(): Promise<getBillingAddressesResponse> {
    const { data } = await api.get('/v1/users/me/billing-addresses')
    return data
  },
  async getBillingAddressById(
    billingAddressId: string,
  ): Promise<BillingAddress> {
    const { data } = await api.get(
      `/v1/users/me/billing-addresses/${billingAddressId}`,
    )
    return data
  },
  async updateBillingAddress(
    billingAddressId: string,
    newBillingAddress: BillingAddress,
  ): Promise<BillingAddress> {
    const { data } = await api.put(
      '/v1/users/me/billing-addresses/' + billingAddressId,
      newBillingAddress,
    )
    return data
  },
  async createBillingAddress(
    newBillingAddress: BillingAddress,
  ): Promise<BillingAddress> {
    const { data } = await api.post(
      '/v1/users/me/billing-addresses/',
      newBillingAddress,
    )
    return data
  },
  async deleteBillingAddress(billingAddressId: string): Promise<any> {
    const { data } = await api.delete(
      '/v1/users/me/billing-addresses/' + billingAddressId,
    )
    return data
  },
  async createPayoutMethodMultiversx(
    address: string,
    signature: string,
    authToken: string,
    payoutCurrency: PayoutCurrency,
  ): Promise<UserPayoutMethod> {
    const { data } = await api.post('/v1/users/me/payout-methods/multiversx', {
      address,
      signature,
      authToken,
      payoutCurrency,
    })
    return data
  },
  async createPayoutMethodMultiversxV2(
    accessToken: string,
    payoutCurrency: PayoutCurrency,
  ): Promise<UserPayoutMethod> {
    const { data } = await api.post('/v2/users/me/payout-methods/multiversx', {
      accessToken,
      payoutCurrency,
    })
    return data
  },
  async getUserPayoutMethods(): Promise<getUserPayoutMethodsResponse> {
    const { data } = await api.get('/v1/users/me/payout-methods')
    return { pagination: {}, userPayoutMethods: data }
  },
  async updateUserPreference({
    userPayoutMethodId,
    lang,
  }: {
    userPayoutMethodId?: string
    lang?: Language
  }): Promise<UserPreferences> {
    const { data } = await api.put(`/v1/users/me/preferences`, {
      userPayoutMethodId,
      lang,
    })
    return data
  },
  async deleteUserPayoutMethod(userPayoutMethodId: string): Promise<void> {
    await api.delete(`/v1/users/me/payout-methods/${userPayoutMethodId}`)
  },
  async createOrder(
    propertyId: string,
    quantity: number,
    priceDisplayed: number,
    currency: string,
    paymentMethod: string,
    walletId: string,
    billingAddressId: string,
    locale: string,
  ): Promise<CreateOrderResult> {
    const successUrl = `${getBaseUrl(
      locale,
    )}/properties/${propertyId}/checkout-complete/?amount=${quantity}`
    const cancelUrl = `${getBaseUrl(
      locale,
    )}/properties/${propertyId}/checkout/?fractionAmount=${quantity}&walletId=${walletId}&billingAddressId=${billingAddressId}&status=failed`

    const { data } = await api.post(`/v1/users/me/orders`, {
      quantity,
      priceDisplayed,
      currency,
      successUrl,
      cancelUrl,
      paymentMethod,
      walletId,
      billingAddressId,
      propertyId,
    })

    return data
  },
  async getOrders({
    page,
    pageSize,
    orderBy,
    order,
  }: {
    page?: number
    pageSize?: number
    orderBy?: string
    order?: 'DESC' | 'ASC'
  }): Promise<GetOrdersResult> {
    const { data } = await api.get('/v1/users/me/orders', {
      params: { page, pageSize, orderBy, order },
    })
    return data
  },
}

export default UserService
