import React from 'react'
import { useTranslation } from 'next-i18next'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import FractionsCardPreview from './FractionsCardPreview'
import YieldHistoryCard from './YieldHistoryCard'
import Link from 'next/link'
import routes from '../../../config/routes'

export default function DashboardPreviewCard() {
  const { t } = useTranslation('home')

  return (
    <>
      <Link href={routes.dashboard} className="flex w-fit items-center pb-1.5">
        <p className="font-bold text-neutralBlack/80">
          {t('dashboardPreview.title')}
        </p>
        <HeroIcons.ChevronRightIcon className="h-5 w-5 text-neutralBlack/80" />
      </Link>
      <div className="relative grid h-full grid-cols-1 gap-3 rounded-md sm:grid-cols-2">
        <FractionsCardPreview />
        <YieldHistoryCard />
      </div>
    </>
  )
}
