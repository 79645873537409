import React from 'react'
import { useTranslation } from 'next-i18next'
import { Property } from '../../../types/Property/Property'
import { DateFormat, formatDateString } from '../../../lib/utils/formatDate'
import useLocale from '../../../hooks/useLocale'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import Link from 'next/link'
import routes from '../../../config/routes'

interface UpcomingPropertySlideProps {
  property: Property
  onClick?: (property: Property) => void
}

export default function UpcomingPropertySlide({
  property,
  onClick,
}: UpcomingPropertySlideProps) {
  const { t } = useTranslation('home')
  const { locale } = useLocale()

  return (
    <Link
      href={{ pathname: routes.property.replace('[propertyId]', property.id) }}
      onClick={() => onClick?.(property)}
      className="h-full w-full bg-purpleGradient"
    >
      <div className="absolute left-3 top-3 rounded-lg bg-orange-500 px-1.5 py-0.5">
        <p className="text-xs font-medium text-neutralWhite">
          {t('carousel.slides.upcomingSale.title')}
        </p>
      </div>

      <div className="h-4/5">
        {property.images && property.images[0] ? (
          <img
            src={property.images[0].url}
            className="aspect-[16/9] h-full w-full bg-neutralGray/30 object-cover"
            alt={property.translation.title + ' photo'}
          />
        ) : (
          <img
            src="/img/logos/shelters/logotype-white.svg"
            className="absolute left-1/2 top-[40%] h-12 -translate-x-1/2 -translate-y-1/2"
            alt="Shelters logo 3d"
          />
        )}
      </div>

      <div className="absolute bottom-0 right-0 flex w-full items-center bg-white p-3">
        <div>
          <p className="font-medium text-neutralBlack">
            {property.translation.title}
          </p>
          <div className="flex items-center text-sm text-orange-600/60">
            <HeroIcons.ClockIcon className="mr-1 h-auto w-5" />
            <p>{t('carousel.slides.upcomingSale.availableOn')}</p>
            <p className="ml-1 font-medium">
              {formatDateString(
                property.fundingStartAt.toString(),
                locale,
                DateFormat.CUSTOM_FULL,
              )}
            </p>
          </div>
        </div>

        <div className="flex grow items-center justify-end">
          <HeroIcons.ArrowRightIcon
            className="h-8 w-auto rounded-full border border-neutralBlack/60 p-1 text-neutralBlack"
            aria-hidden="true"
          />
        </div>
      </div>
    </Link>
  )
}
