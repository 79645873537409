import React, { useEffect, useRef } from 'react'
import { useAuth } from '../providers/AuthProvider'
import { NavigationItem } from '../types/Navigation'
import { classNames } from '../lib/utils/classNames'
import Link from 'next/link'
import routes from '../config/routes'
import { HeroIcons } from '../lib/utils/heroIcons'
import { useTranslation } from 'next-i18next'
import LanguageSelect from './LanguageSelect'
import { Disclosure, Transition } from '@headlessui/react'

const buttonStyle = {
  active: 'bg-purpleGradient',
  inactive: 'bg-white hover:bg-brandLightPurple/20',
}

const buttonTextStyle = {
  active: 'text-neutralWhite',
  inactive: 'bg-purpleGradient bg-clip-text text-transparent',
}

const buttonIconStyle = {
  active: 'text-neutralWhite',
  inactive: 'text-purple-800',
}

interface NavbarProps {
  navigation: NavigationItem[]
}

export default function Navbar({ navigation }: NavbarProps) {
  const { t } = useTranslation('common')

  const { isLoggedIn, isLoading, logout } = useAuth()

  const disclosureRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        disclosureRef.current &&
        !disclosureRef.current.contains(event.target as Node)
      ) {
        const disclosureButton = disclosureRef.current.querySelector(
          'button[aria-expanded="true"]',
        ) as HTMLElement
        disclosureButton?.click()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative z-40 w-full grow" ref={disclosureRef}>
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <nav className="border-1 sticky z-40 w-full rounded-lg border-sheltersBlue/20 bg-neutralWhite p-1.5 shadow">
              <div className="flex items-center">
                <Disclosure.Button
                  as={Link}
                  href={routes.index}
                  className="flex items-center"
                >
                  <img
                    src="/img/logos/shelters/logo-cropped-colours.svg"
                    alt="shelters logo"
                    className="h-[36px] w-[48px]"
                  />
                  <img
                    src="/img/logos/shelters/logotype-cropped-colours.svg"
                    alt="shelters logotype"
                    className="ml-3 block h-[30px] w-auto md:hidden 2xl:block"
                  />
                </Disclosure.Button>

                <div className="hidden grow items-center justify-center space-x-3 md:flex">
                  {navigation.map((navItem, index) => (
                    <Link
                      href={navItem.href}
                      key={index}
                      className={classNames(
                        'group flex w-40 items-center justify-center rounded-md px-3 py-1.5',
                        navItem.isActive
                          ? buttonStyle.active
                          : buttonStyle.inactive,
                      )}
                    >
                      <div className="flex items-center">
                        <navItem.icon
                          className={classNames(
                            'mr-1 h-5 w-5',
                            navItem.isActive
                              ? buttonIconStyle.active
                              : buttonIconStyle.inactive,
                          )}
                        />
                        <p
                          className={classNames(
                            'leading-none',
                            navItem.isActive
                              ? buttonTextStyle.active
                              : buttonTextStyle.inactive,
                          )}
                        >
                          {navItem.name}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>

                <div className="hidden items-center space-x-1.5 md:flex">
                  <LanguageSelect minimalDisplay={true} />
                  {!isLoading && (
                    <>
                      {!isLoggedIn ? (
                        <>
                          <div className="flex items-center">
                            <Link
                              href={routes.login}
                              className="group flex items-center justify-center overflow-hidden rounded-md border border-sheltersBlue bg-white px-2 py-1.5 hover:bg-sheltersBlue/20 group-hover:cursor-pointer"
                            >
                              <span className="relative h-8 w-8 overflow-hidden rounded-full bg-sheltersBlue">
                                <HeroIcons.UserIcon className="absolute h-8 w-8 p-1.5 text-white transition-opacity duration-500 group-hover:animate-launchDisappear group-hover:opacity-0" />
                                <HeroIcons.RocketLaunchIcon className="absolute mx-auto h-8 w-8 p-1.5 text-white  opacity-0 group-hover:animate-launch group-hover:opacity-100" />
                              </span>
                              <p className="ml-2 text-sm text-sheltersBlue">
                                {t('navbar.login')}
                              </p>
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex items-center">
                            <button
                              onClick={logout}
                              className="group flex items-center justify-center overflow-hidden rounded-md  px-2 py-1.5 hover:bg-sheltersBlue/20 group-hover:cursor-pointer"
                            >
                              <p className="mr-1 text-sm text-sheltersBlue">
                                {t('navbar.logout')}
                              </p>
                              <HeroIcons.ArrowRightOnRectangleIcon className="h-5 w-5 text-sheltersBlue" />
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="flex grow items-center justify-end md:hidden">
                  <Disclosure.Button>
                    {open ? (
                      <HeroIcons.XMarkIcon className="h-[38px] w-auto text-purple-700" />
                    ) : (
                      <HeroIcons.Bars3Icon className="h-[38px] w-auto text-purple-700" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </nav>

            <Transition
              enter="transition-transform duration-300 ease-out"
              enterFrom="-translate-y-full opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition-transform duration-200 ease-in"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-full opacity-0"
            >
              <Disclosure.Panel className="absolute z-30 -mt-1 block w-full rounded-b-lg border-b border-l border-r border-sheltersBlue/10 bg-neutralWhite px-1.5 pb-1.5 pt-2.5 text-gray-500 sm:hidden">
                <div className="flex flex-col space-y-1.5 border-b pb-1.5">
                  {navigation.map((navItem, index) => (
                    <Disclosure.Button
                      as={Link}
                      href={navItem.href}
                      key={index}
                      className={classNames(
                        'group flex items-center rounded-md px-2 py-1',
                        navItem.isActive
                          ? buttonStyle.active
                          : buttonStyle.inactive,
                      )}
                    >
                      <navItem.icon
                        className={classNames(
                          'mr-1 h-5 w-5',
                          navItem.isActive
                            ? buttonIconStyle.active
                            : buttonIconStyle.inactive,
                        )}
                      />
                      <p
                        className={classNames(
                          navItem.isActive
                            ? buttonTextStyle.active
                            : buttonTextStyle.inactive,
                        )}
                      >
                        {navItem.name}
                      </p>
                    </Disclosure.Button>
                  ))}
                </div>

                <div className="flex items-center pt-1.5">
                  <div>
                    <LanguageSelect minimalDisplay={false} />
                  </div>
                  <div className="grow"></div>
                  <div>
                    {!isLoading && (
                      <>
                        {!isLoggedIn ? (
                          <>
                            <div className="flex items-center">
                              <Link
                                href={routes.login}
                                className="group flex items-center justify-center overflow-hidden rounded-md border border-sheltersBlue bg-white px-2 py-1.5 hover:bg-sheltersBlue/20 group-hover:cursor-pointer"
                              >
                                <span className="relative h-6 w-6 overflow-hidden rounded-full bg-sheltersBlue">
                                  <HeroIcons.UserIcon className="absolute h-6 w-6 p-1 text-white transition-opacity duration-500 group-hover:animate-launchDisappear group-hover:opacity-0" />
                                  <HeroIcons.RocketLaunchIcon className="absolute mx-auto h-6 w-6 p-1 text-white  opacity-0 group-hover:animate-launch group-hover:opacity-100" />
                                </span>
                                <p className="ml-2 text-sm text-sheltersBlue">
                                  {t('navbar.login')}
                                </p>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex items-center">
                              <button
                                onClick={logout}
                                className="group flex items-center justify-center overflow-hidden rounded-md  px-2 py-1.5 hover:bg-sheltersBlue/20 group-hover:cursor-pointer"
                              >
                                <p className="mr-1 text-sm text-sheltersBlue">
                                  {t('navbar.logout')}
                                </p>
                                <HeroIcons.ArrowRightOnRectangleIcon className="h-5 w-5 text-sheltersBlue" />
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}
