import React from 'react'
import ContactUsBento from './ContactUsBento'
import SocialsBento from './SocialsBento'
import ReferralBento from './ReferralBento'
import InvestmentSimulationBento from './InvestmentSimulationBento'

export default function MoreAboutBento() {
  return (
    <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
      <div className="col-span-1 h-full w-full overflow-hidden rounded-md bg-white p-3">
        <InvestmentSimulationBento />
      </div>

      <div className="grid-rows-auto col-span-1 grid gap-3 sm:col-span-2 sm:grid-rows-2">
        <div className="h-full overflow-hidden rounded-md bg-white py-3">
          <ContactUsBento />
        </div>

        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
          <div className="order-2 h-full rounded-md bg-white p-3 sm:order-1">
            <SocialsBento />
          </div>
          <div className="order-1 h-full rounded-md bg-white p-3 sm:order-2">
            <ReferralBento />
          </div>
        </div>
      </div>
    </div>
  )
}
