import React from 'react'

export enum NavigationPages {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  STORE = 'store',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  LOGIN = 'login',
}

export interface NavigationItem {
  id: NavigationPages
  name: string
  href: string
  isActive: boolean
  icon: any
}

export interface NavigationTab {
  name: string
  key: NavigationTabsAccount
  icon: React.ElementType
}

export enum NavigationTabsAccount {
  PROFILE = 'profile',
  WALLETS = 'wallets',
  PAYOUT = 'payout',
  SECURITY = 'security',
  PURCHASE_HISTORY = 'purchaseHistory',
}
