import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { HeroIcons } from '../../../lib/utils/heroIcons'
import EmblaCarousel from '../../EmblaCarousel'
import PropertyService from '../../../services/property.service'
import useLocale from '../../../hooks/useLocale'
import { Property } from '../../../types/Property/Property'
import UpcomingPropertySlide from './UpcomingPropertySlide'
import SeeAllOpportunitiesSlide from './SeeAllOpportunitiesSlide'
import LatestFundingPropertySlide from './LatestFundingPropertySlide'
import LatestFundedPropertySlide from './LatestFundedPropertySlide'
import routes from '../../../config/routes'
import Link from 'next/link'
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service'
import { mapItem } from '../../../lib/utils/helperGtm'

export default function LatestListingCarousel() {
  const { t } = useTranslation('home')
  const { locale } = useLocale()

  const [upcomingProperties, setUpcomingProperties] = useState<Property[]>([])
  const [latestFundedProperties, setLatestFundedProperties] = useState<
    Property[]
  >([])
  const [latestFundingProperties, setLatestFundingProperties] = useState<
    Property[]
  >([])

  const fetchUpcomingProperties = async () => {
    try {
      const resUpcomingProperties = await PropertyService.getProperties({
        page: 1,
        pageSize: 2,
        filter: ['not_started'],
        filterBy: ['fundingStatus'],
        filterOperator: ['='],
        orderBy: 'createdAt',
        order: 'DESC',
        lang: locale,
      })
      setUpcomingProperties(resUpcomingProperties.properties)
    } catch (e) {}
  }
  const fetchLatestFundedProperties = async () => {
    try {
      const resFundedProperties = await PropertyService.getProperties({
        page: 1,
        pageSize: 2,
        filter: ['funded'],
        filterBy: ['fundingStatus'],
        filterOperator: ['='],
        orderBy: 'createdAt',
        order: 'DESC',
        lang: locale,
      })
      setLatestFundedProperties(resFundedProperties.properties)
    } catch (e) {}
  }
  const fetchLatestFundingProperties = async () => {
    try {
      const resFundingProperties = await PropertyService.getProperties({
        page: 1,
        pageSize: 2,
        filter: ['funding'],
        filterBy: ['fundingStatus'],
        filterOperator: ['='],
        orderBy: 'createdAt',
        order: 'DESC',
        lang: locale,
      })
      setLatestFundingProperties(resFundingProperties.properties)
    } catch (e) {}
  }

  const handlePropertyClick = (
    property: Property,
    itemListId: string,
    itemListName: string,
  ): void => {
    GoogleTagManagerService.ClickItem({
      itemListId,
      itemListName,
      item: mapItem(property),
    })
  }

  useEffect(() => {
    fetchUpcomingProperties()
    fetchLatestFundedProperties()
    fetchLatestFundingProperties()
  }, [])

  const slides: React.JSX.Element[] = [
    ...latestFundingProperties.map((property, index) => (
      <div key={'latestFundingSlide' + index} className="relative h-full">
        <LatestFundingPropertySlide
          property={property}
          onClick={(property) =>
            handlePropertyClick(
              property,
              'home_latest_funding',
              'Derniers financements en cours',
            )
          }
        />
      </div>
    )),
    ...upcomingProperties.map((property, index) => (
      <div key={'upcomingSlide' + index} className="relative h-full">
        <UpcomingPropertySlide
          property={property}
          onClick={(property) =>
            handlePropertyClick(
              property,
              'home_upcoming_property',
              'Prochain financements',
            )
          }
        />
      </div>
    )),
    ...latestFundedProperties.map((property, index) => (
      <div key={'latestFundedSlide' + index} className="relative h-full">
        <LatestFundedPropertySlide
          property={property}
          onClick={(property) =>
            handlePropertyClick(
              property,
              'home_latest_funded',
              'Derniers financements',
            )
          }
        />
      </div>
    )),
    <SeeAllOpportunitiesSlide key="seeAllOportunitiesSlide" />,
  ]

  return (
    <>
      <Link href={routes.properties} className="flex w-fit items-center pb-1.5">
        <p className="font-bold text-neutralBlack/80">{t('carousel.title')}</p>
        <HeroIcons.ChevronRightIcon className="h-5 w-5 text-neutralBlack/80" />
      </Link>
      <div>
        <EmblaCarousel slides={slides} />
      </div>
    </>
  )
}
