import { CurrencyEnum } from '../types/Currency'

export interface GtmParameters {
  event: GtmEvent
  noninteraction?: boolean
  value?: string | number
  currency?: CurrencyEnum
  userId?: string
  email?: string
  conversionId?: string // twitter parameter
  contentName?: string // twitter parameter
  contentId?: string // twitter parameter
  contentType?: string // twitter parameter
  contents?: GtmParameterContent[] // twitter parameter
  method?: string
  transactionId?: string
  itemListName?: string // google analytic parameter
  itemListId?: string // google analytic parameter
  items?: GtmParameterItem[] // google analytic parameter
}

export interface GtmParameterItem {
  item_id: string
  item_name: string
  affiliation?: string
  coupon?: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price: number
  quantity: number
}

export interface GtmParameterContent {
  content_id: string
  content_name: string
  content_type: string
  content_price: number
  num_items: number
  content_group_id?: string
}

export type GtmEvent =
  | 'Login'
  | 'Signup'
  | 'Identify'
  | 'GenerateLead'
  | 'GenerateProspect'
  | 'ViewItemList'
  | 'ViewItem'
  | 'ClickItem'
  | 'Contact'
  | 'ViewCart'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'Purchase'

export const GoogleTagManagerService = {
  trackEvent(gtmParameters: GtmParameters) {
    console.log('trackEvent', gtmParameters)
    // @ts-ignore
    window.dataLayer = window.dataLayer || []

    // @ts-ignore
    dataLayer.push(gtmParameters)
  },

  Lead({ value, currency }: { value: number; currency: CurrencyEnum }) {
    this.trackEvent({
      event: 'GenerateLead',
      value,
      currency,
    })
  },

  Prospect({ value, currency }: { value: number; currency: CurrencyEnum }) {
    this.trackEvent({
      event: 'GenerateProspect',
      value,
      currency,
    })
  },

  Identify({ userId, email }: { userId: string; email: string }) {
    this.trackEvent({
      event: 'Identify',
      noninteraction: true,
      userId,
      email,
    })
  },

  Login({ method }: { method: 'password' | 'google' }) {
    this.trackEvent({
      event: 'Login',
      method,
    })
  },

  Signup({ method }: { method: 'password' | 'google' }) {
    this.trackEvent({
      event: 'Signup',
      method,
    })
  },

  AddToCart({
    value,
    currency,
    items,
    contents,
  }: {
    value: number
    currency: CurrencyEnum
    items: GtmParameterItem[]
    contents: GtmParameterContent[]
  }) {
    this.trackEvent({
      event: 'AddToCart',
      value,
      currency,
      items,
      contents,
    })
  },

  ViewCart({
    value,
    currency,
    items,
    contents,
  }: {
    value: number
    currency: CurrencyEnum
    items: GtmParameterItem[]
    contents: GtmParameterContent[]
  }) {
    this.trackEvent({
      event: 'ViewCart',
      value,
      currency,
      items,
      contents,
    })
  },

  InitiateCheckout({
    value,
    currency,
    items,
    contents,
  }: {
    value: number
    currency: CurrencyEnum
    items: GtmParameterItem[]
    contents: GtmParameterContent[]
  }) {
    this.trackEvent({
      event: 'InitiateCheckout',
      value,
      currency,
      items,
      contents,
    })
  },

  Purchase({
    value,
    currency,
    transactionId,
    items,
    contents,
  }: {
    value: number
    currency: CurrencyEnum
    transactionId: string
    items: GtmParameterItem[]
    contents: GtmParameterContent[]
  }) {
    this.trackEvent({
      event: 'Purchase',
      value,
      currency,
      transactionId,
      items,
      contents,
    })
  },

  ViewItem({
    item,
    content,
  }: {
    item: GtmParameterItem
    content: GtmParameterContent
  }) {
    this.trackEvent({
      event: 'ViewItem',
      items: [item],
      contents: [content],
    })
  },

  ViewItemList({
    items,
    contents,
  }: {
    items: GtmParameterItem[]
    contents: GtmParameterContent[]
  }) {
    this.trackEvent({
      event: 'ViewItemList',
      items,
      contents,
    })
  },

  ClickItem({
    itemListId,
    itemListName,
    item,
  }: {
    itemListId: string
    itemListName: string
    item: GtmParameterItem
  }) {
    this.trackEvent({
      event: 'ClickItem',
      itemListId,
      itemListName,
      items: [item],
    })
  },
}
