import React from 'react'
import { useTranslation } from 'next-i18next'

interface Socials {
  name: string
  href: string
  imgSrc: string
}

export default function SocialsBento() {
  const { t } = useTranslation('home')

  const socials: Socials[] = [
    {
      name: 'instagram',
      href: process.env.NEXT_PUBLIC_INSTAGRAM_PAGE ?? '',
      imgSrc: '/img/logos/socials/instagram.svg',
    },
    {
      name: 'telegram',
      href: process.env.NEXT_PUBLIC_TELEGRAM_PAGE ?? '',
      imgSrc: '/img/logos/socials/telegram.svg',
    },
    {
      name: 'x',
      href: process.env.NEXT_PUBLIC_TWITTER_PAGE ?? '',
      imgSrc: '/img/logos/socials/twitter.svg',
    },
    {
      name: 'linkedin',
      href: process.env.NEXT_PUBLIC_LINKEDIN_PAGE ?? '',
      imgSrc: '/img/logos/socials/linkedin.svg',
    },
    {
      name: 'discord',
      href: process.env.NEXT_PUBLIC_DISCORD_INVITE ?? '',
      imgSrc: '/img/logos/socials/discord.svg',
    },
  ]

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <div className="mb-3">
        <p className="text-center font-medium text-neutralBlack">
          {t('bento.socialsBento.title')}
        </p>
        <div className="flex items-center justify-center">
          <p className="text-center text-sm text-neutralBlack/80 ">
            {t('bento.socialsBento.text')}
          </p>
          <p className="ml-1 bg-gradient-to-l from-brandDarkPurple to-brandLightPurple bg-clip-text font-medium text-transparent">
            Shelters
          </p>
        </div>
      </div>

      <div className="relative grid w-full grid-cols-5">
        {socials.map((s, index) => (
          <div
            className="flex w-full items-center justify-center hover:scale-[103%] hover:transition"
            key={index}
          >
            <a
              href={s.href}
              target="_blank"
              className="group flex items-center rounded-full border-2 border-slate-50 bg-purpleGradient px-1.5 py-1.5 shadow-xl"
            >
              <img className="w-9" src={s.imgSrc} alt={`${s.name} logo`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
