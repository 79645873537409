import React from 'react'
import { useTranslation } from 'next-i18next'
import { HeroIcons } from '../../../lib/utils/heroIcons'

export default function ContactUsBento() {
  const { t } = useTranslation('home')

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <div className="relative mb-3 flex h-14 w-full items-center justify-center">
        <img
          src="/img/photos/people/team-rounded-min.png"
          alt="Adrien Vandenbossche - Shelters CEO"
        />
      </div>

      <div className="mb-3">
        <p className="text-center font-medium text-neutralBlack">
          {t('bento.contactUsBento.title')}
        </p>
        <p className="text-center text-sm text-neutralBlack/80 ">
          {t('bento.contactUsBento.text')}
        </p>
      </div>

      <a
        href={process.env.NEXT_PUBLIC_CALENDLY}
        target="_blank"
        className="group flex items-center rounded-lg border border-sheltersBlue bg-sheltersBlue px-3 py-1.5 text-neutralWhite transition hover:bg-white hover:text-sheltersBlue"
      >
        <div className="flex items-center space-x-1">
          <div className="text-sm">{t('bento.contactUsBento.ctaLink')}</div>
          <HeroIcons.PhoneIcon
            className="mx-auto h-5 w-auto group-hover:animate-shake"
            aria-hidden="true"
          />
        </div>
      </a>
    </div>
  )
}
