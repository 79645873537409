import { Property } from '../../types/Property/Property'
import {
  GtmParameterContent,
  GtmParameterItem,
} from '../../services/google-tag-manager.service'
import { formatPrice } from './formatNumber'

export const mapContent = (
  property: Property,
  quantity?: number,
): GtmParameterContent => {
  return {
    content_id: property.id,
    content_name: property.translation.title,
    content_price: formatPrice(property.fractionCurrentPrice),
    num_items: quantity ?? 1,
    content_type: 'originals',
  }
}

export const mapItem = (
  property: Property,
  quantity?: number,
): GtmParameterItem => {
  return {
    item_id: property.id,
    item_name: property.translation.title,
    price: formatPrice(property.fractionCurrentPrice),
    quantity: quantity ?? 1,
  }
}
